<template>
    <div class="poolingRules-container">
        <CSDialog
                dialog-title="公摊规则"
                dialog-width='630px'
                class="tenant-detail-dialog"
                :dialog-visible="dealResultRules"
                @onClose="dealResultRules = false"
                dialog-header-class="alert-bg"
                :dialog-show-confirm-btn="false"
                dialog-cancel-btn-text="关闭"
        >
            <template v-slot:dialog-content>
                <div class="wrapper">
                    <div class="from-item">
                        <div class="from-item-top">{{isElectricty?'公摊总用电量':'公摊总用水量'}}</div>
                        <div class="from-item-bottom">
                            <div class="item-bottom">
                                <div class="item-from">{{list.equallySharedDosage}} {{isElectricty?'度':'吨'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="from-item">
                        <div class="from-item-top">{{isElectricty?'公摊电价':'公摊水价'}}</div>
                        <div class="from-item-bottom">
                            <div class="item-bottom">
                                <div class="item-from">{{list.equallySharedPrice}} {{isElectricty?'元/度':'元/吨'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="from-item">
                        <div class="from-item-top">{{isElectricty?'公摊电费总额':'公摊水费总额'}}</div>
                        <div class="from-item-bottom">
                            <div class="item-bottom">
                                <div class="item-from">{{list.equallySharedTotalAmount}}元</div>
                            </div>
                        </div>
                    </div>
                    <div class="from-item">
                        <div class="from-item-top">{{isElectricty?'电费公摊总面积':'水费公摊总面积'}}</div>
                        <div class="from-item-bottom">
                            <div class="item-bottom">
                                <div class="item-from">{{list.equallySharedSpace}}m²</div>
                            </div>
                        </div>
                    </div>
                    <div class="from-item">
                        <div class="from-item-top">单位面积公摊费</div>
                        <div class="from-item-bottom">
                            <div class="item-bottom">
                                <div class="item-from">{{list.equallySharedPriceAvg}} 元/m²</div>
                            </div>
                        </div>
                    </div>
                </div>

            </template>
        </CSDialog>
    </div>
</template>

<script>
    import CSDialog from "@/components/common/CSDialog";
    export default {
        name: "PoolingRules",
        props:{
            isElectricty:{
                default:false
            }
        },
        created() {
            this.$vc.on('polingRulesCsD',(item) => {
                this.list = item;
                this.dealResultRules = true;

            })
        },
        data(){
            return{
                dealResultRules:false,
                list:""
            }
        },
        components:{
            CSDialog
        }
    }
</script>

<style scoped>
    .wrapper{
        padding: 30px;
    }
    .from-item {
        font-size: 24px;
        color: #000000;
        display: flex;
        align-items: top;
        text-align: right;
        margin-bottom: 22px;
    }

    .from-item-top{
        width: 220px;
        margin-right: 40px;

    }
    .item-bottom{
        display: flex;
    }
    .item-from{
        margin-right: 20px;
    }
</style>