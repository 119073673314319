<template>
    <div class='offlinePaymentCS-container'>
        <CSDialog
                dialogTitle="线下付款"
                dialogWidth="826px"
                :dialogVisible="addRoomVisible"
                @onClose="addRoomVisible = false"
                @onConfirm="editRoom"
                dialog-confirm-btn-text="确定">
            <div slot="dialog-content" class="room-form">
                <div class="field-item jsF">
                    <label class="field-item-label">支付渠道</label>
                    <div class="field-item-content">
                        <CSRadio :items="{0: '现金支付', 1: '银行转账',2: '支付宝支付',3: '微信支付'}" v-model="modePayment"></CSRadio>
                    </div>
                </div>
                <div class="field-item jsF">
                    <label class="field-item-label">付款凭证</label>
                    <div class="field-item-content">
                        <input type="file" id="upload-file" style="display: none" />
                        <div>
                            <div
                                    v-for="(src, imgIndex) in voucher"
                                    :key="src"
                                    class="photo-view"
                            >
                                <img :src="src" />
                                <i
                                        class="icon-close"
                                        @click="deletePhotos(imgIndex)"
                                ></i>
                            </div>
                            <div
                                    class="upload-photo"
                                    @click="uploadArchive"
                                    v-if="voucher.length < 1"
                            >
                                <div class="text-center">
                                    <img
                                            src="../../../../assets/upload.png"
                                            alt=""
                                            style="margin: 5px auto"
                                    />
                                    <p style="color: #999;">上传照片</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </CSDialog>
    </div>
</template>

<script>
    import CSRadio from "@/components/common/CSRadio";
    import CSDialog from "@/components/common/CSDialog";
    import {billOfflinePaymentUrl} from '@/requestUrl';
    export default {
        name: "OfflinePaymentCSd",
        created() {
            this.$vc.on('addRoomVisible', (item) => {
                this.modePayment = item.modePayment;
                this.item = item;
                this.addRoomVisible = true;
                this.voucher = [];
            })
        },
        data() {
            return {
                item:{},
                addRoomVisible: false,
                modePayment: 0,
                voucher:[]
            }
        },
        components: {
            CSRadio,
            CSDialog
        },
        methods: {
            /**
             * 上传图片
             * @param {String} base64 base64格式
             *  */
            async uploadPhoto(base64) {
                return new Promise((reslove, reject) => {
                    this.$vc.http.post(
                        "uploadImg",
                        "upload",
                        {
                            img: base64,
                        },
                        {
                            headres: {
                                "Content-Type": "application/json",
                            },
                        },
                        (resStr, res) => {
                            reslove(res.body.fileSaveName);
                        },
                        (errText, err) => {
                            reject(errText);
                        }
                    );
                });
            },
            /**
             * 选择图片
             * @param {HTMLEvent} event
             *  */
            choosePhoto(event) {
                return new Promise(async (resolve) => {
                    var photoFiles = event.target.files;
                    if (photoFiles && photoFiles.length > 0) {
                        // 获取目前上传的文件
                        var file = photoFiles[0]; // 文件大小校验的动作
                        if (file.size > 1024 * 1024 * 2) {
                            this.$vc.toast("图片大小不能超过 2MB!");
                            return false;
                        }
                        let formData = new FormData(); //新建FormData对象
                        formData.append("files", photoFiles[0]);
                        formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
                        formData.append("compress", "true");
                        const photo = await this.Constants.uploadFileOne(formData);
                        if (photo) {
                            resolve(this.Constants.BUCKET_NAMES.IMG.visitUrl + photo);
                        }
                        // const photo = await this.Constants.uploadFileOne(formData);
                        // var reader = new FileReader(); //新建FileReader对象
                        // reader.readAsDataURL(file); //读取为base64
                        // reader.onloadend = async (e) => {
                        //     const photo = await this.uploadPhoto(
                        //         reader.result
                        //     ).catch((err) => {
                        //         console.error("上传照片失败");
                        //         return "";
                        //     });
                        //     resolve(photo);
                        // };
                    }
                });
            },
            editRoom() {
                if(!this.voucher){
                    this.$vc.toast(`请上传付款凭证`);
                    return
                }
                if(this.voucher&&this.voucher.length <= 0){
                    this.$vc.toast(`请上传付款凭证`);
                    return
                }
                this.$fly.get(billOfflinePaymentUrl,{
                    id:this.item.id,
                    modePayment:this.modePayment,
                    payOrder:this.voucher[0]
                }).then(res => {
                    if(res.code != 0){
                        return
                    }
                    this.$vc.toast(`缴费成功`);
                    this.$vc.emit('queryBillWarter',{})
                    this.addRoomVisible = false;

                })
            },
            deletePhotos(index) {
                this.voucher.splice(index, 1);
            },
            /**
             * 上传凭证
             *  */
            uploadArchive() {
                const el = document.querySelector("#upload-file");
                el.addEventListener(
                    "change",
                    async (e) => {
                        this.voucher.push(await this.choosePhoto(e));
                    },
                    { once: true }
                );
                el.click();
            },
        }
    }
</script>

<style scoped lang="stylus">
    .room-form {
        padding: 27px 30px 10px;

        .field-item {
            margin-bottom: 22px;
            font-size: 0;

            &-label {
                font-size: 24px;
                text-align: right;
                width: 208px;
                margin-right: 40px;
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                margin-bottom: 0;
            }

            &-content {
                display: inline-block;
                vertical-align: middle;
                font-size: 24px;

                input {
                    width: 400px;
                    height: 40px;
                    text-indent: 5px;
                    border-radius: 4px;
                    border: 1px solid #979797;

                    &::placeholder {
                        color: #999;
                    }
                }

                select {
                    width: 220px;
                    text-indent: 5px;
                }
            }
        }

        .tips {
            color: #999;
            font-size: 20px;
        }

        .field-font {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 33px;
            color: #999999;
        }
    }

    .location-search {
        width: 100%;
        display: flex;
    }

    .location-search .location-input {
        border-radius: 4px;
        border: 1px solid #999;
        padding: 0 10px;
        vertical-align: middle;
        width: 30px;
        height: 40px;
        box-sizing: border-box;
    }

    .font {
        font-size: 20px;
        vertical-align: middle;
        margin-left: 30px;
        float: right;
        height: 40px;
        padding: 0;
        width: 60px;
        line-height: 40px;
        box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
    }

    .selectDive {
        padding: 0;
        width: 20px;
        height: 20px;
        border: 1px solid black;
        display: inline-block;
        border-radius: 3px;
        margin-right: 5px;
        position: relative;
        background-image: "../../assets/未选中.png"
    }

    input[type='checkbox'] {
        width: 0px !important
        display inline-block
        opacity 1
        margin-right 25px
        height 20px !important
    }

    .roomli {
        display flex
        align-items center
    }

    .roomMove {
        cursor pointer
    }

    .field-item-label {
        width 120px !important
    }
</style>
